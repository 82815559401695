*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

html {
  
  scroll-behavior: smooth;
}

@media (min-width: 1440px) {
  html {
    zoom: 1.0;
  }
}

@media (min-width: 2560px) {
  html {
    zoom: 1.5;
  }
}

@media (min-width: 3860px) {
  html {
    zoom: 2.5;
  }
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

body {
  font-size: 1.6rem;
  background: var(--bg);
  font-family: 'Open Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
}
/*hola*/
.container {
  
  width: 100%;
  height: 100%;
  padding: 4rem 1rem;
  margin: 0 auto;
 
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper {
  width: 100%;
  height:'auto';
}

.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  transition: opacity 0.3s ease;
}

.image-container2 {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
@media (max-width: 450px) {
  .image-container2{
    width: 240px;
    height: 120px;
  }
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99.4%;
  background-color: rgba(25, 25, 25, 0.7);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  
}


.image-container:hover .image-overlay {
  opacity: 1;
  
}


.img-accordion {
  width: 100%;
  height: 80vh;
  display: flex;
}
.img {
  flex: 1;
  height: 100%;
  width: 60px;
  cursor: pointer;
  margin: 0;
  transition: all 0.7s;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.img1{
  background-image: url('./assets/homepage/sodar.jpg');
}
.img2{
  background-image: url('./assets/homepage/obrasciviles.jpg');
}
.img3{
  background-image: url('./assets/homepage/montaje.jpg');
}
.img4{
  background-image: url('./assets/homepage/equipamientoysensores.jpg');
}
.img5{
  background-image: url('./assets/homepage/panelessolares.jpg');
}

.img p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  transform: rotate(-90deg);
  transform-origin: 0% 0%;
  transition: all 0.3s;
  text-transform: uppercase;
  white-space: nowrap;
}

.img-accordion:hover .img {
  transform: skew(0);
}

.img:hover {
  flex: 5;
}

.img:hover p {
  background-color: transparent;
  border: 2px solid #222;
  transform: rotate(0deg);
}


@media (max-width: 768px) {
  .img-accordion {
    flex-direction: column;
    height: auto;
  }

  .img {
    width: 100%;
    margin: 0px 0;
    flex: none;
  }

  .img p {
    transform: none;
    position: relative;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    text-align: center;
    white-space: normal;
    transform: none;
    margin: 0;
    bottom: auto;
    left: auto;
  }

  .img:hover p {
    background-color: transparent;
    border: none;
  }
}

.leaflet-container{
  width: 100%;
  height: 90vh;
  position: relative;
  margin: 0 auto; /* Centra horizontalmente */

}

.custom-marker {
  background-color: rgba(0, 4, 67, 0.8); ; /* Color de fondo del círculo */
  border: 2px solid #fff; /* Borde blanco para mayor contraste */
  border-radius: 50%; /* Establece el círculo */
  width: 30px; /* Ancho del marcador */
  height: 30px; /* Alto del marcador */
  display: flex; /* Permite centrar el número verticalmente */
  justify-content: center; /* Centra el número horizontalmente */
  align-items: center; /* Centra el número verticalmente */
}

.custom-marker span {
  color: white; /* Color del número */
  font-weight: bold; /* Texto en negrita para mayor legibilidad */
}
.contenedor-caida{
  position: relative;
  width: 100%; /* O ajusta según tu preferencia */
  max-width: 1000px; /* Limita el ancho máximo del contenedor */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  overflow: hidden;
}
.caida{
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  animation: caida 1s ease forwards;
}

@keyframes caida {
  from {
    bottom: 100%; /* La imagen empieza arriba */
  }
  to {
    bottom: 0; /* La imagen cae hasta abajo */
  }
}